import auth from '@/services/CRMinitAuth'

const list = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/`, {
    params: query
  });
};

const candidatesAllWillCome = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/all_will_come/`, {
    params: query
  });
};

const candidatesAllWillNotCome = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/all_will_not_come/`, {
    params: query
  });
};

const listTask = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/task_list/`, {
    params: query
  });
}


const listReserve = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/top_cbr_reserve_queue/`, {
    params: query
  });
}

const listExchange = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/top_cbr_exchange_queue/`, {
    params: query
  });
}

const listWait = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/wait_list/`, {
    params: query
  });
}

const listNeedAuthorization = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/need_authorization/`, {
    params: query
  });
}

const listCancel = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/canceled_list/`, {
    params: query
  });
}

const listReserveExchange = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/reserve_and_exchange_queue/`, {
    params: query
  });
}

const listReservedExchanged = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/reserved_and_exchanged/`, {
    params: query
  });
}

const listProcessed = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/processed/`, {
    params: query
  });
}

const listReservedAll = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/all_reserved/`, {
    params: query
  });
};

const listMijnCbrQueue = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/mijn_cbr_queue/`, {
    params: query
  });
};

const parseReservationNumber = (id) => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${id}/get_application_number/`)
};

const listTopCbrQueue = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/top_cbr_queue/`, {
    params: query
  });
}

const find = ID => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/${ID}/`);
}

const findSimilar = ID => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/${ID}/get_similar_candidates/`);
}

const findByNumber = number => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/get_by_number/`, {
    params: {
      number: number
    }
  });
}

const create = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates/`, data);
}

const sendExchange = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates_suggestions/add_suggested_exams/`, data);
}

const addRequests = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/add_exam_requests/`, data);
};

const toggleCoursePhotoConsent = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/course_photo_consent/`);

const removeRequests = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/delete_exam_requests/`, data);
}

const addCustomLog = (candidate_id, data) => {
  return auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${candidate_id}/add_log/`, data);
};

const put = (ID, data) => {
  return auth.put(`${process.env.VUE_APP_CRM_API}candidates/${ID}/`, data);
};

const patch = (ID, data, params) => {
  return auth.patch(`${process.env.VUE_APP_CRM_API}candidates/${ID}/`, data, params);
};

const remove = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/${ID}/archive/`);

const restore = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/${ID}/full_restore/`);

const softRestore = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/${ID}/soft_restore/`);

const markAsProcessed = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/mark_as_processed/`, data);

const getCandidateNumber = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/get_candidate_number/`, data);

const accept = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/accept/`, data);

const checkReservationQueueBulk = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/try_to_reserve/`, data);

const changeCourseBulk = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/change_course/`, data);

const setMijnExam = (ID, data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/set_mijn_exam/`, data);

const removeMijnExam = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/delete_mijn_exam/`);

const assignLabels = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_assign_labels/`, data);

const unAssignLabels = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/bulk_delete_labels/`, data);

const sendEmail = (ID, data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/${ID}/send_email/`, data);

const sendEmailBulk = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/send_emails/`, data);

const bulkSuggestMIJN = (data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/suggest_mijn_cbr_exam/`, data);

const removeFromAccept = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/un_accept/`, data);

const sync = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/topcbr_import/`, data);

const sendToLanding = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/send_to_landing/`);

const setCancel = ID =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/cancel/`);

const changeReapplyState = (ID, data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/${ID}/change_reapply_allowed/`, data);

const changeOnHold = data =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/change_on_hold/`, data);

const changeColor = (candidateIds, colorID) =>
  auth.post(`${process.env.VUE_APP_CRM_API}candidates/actions/change_color/`, {ids: candidateIds, color: colorID});

export default {
  list,
  candidatesAllWillCome,
  candidatesAllWillNotCome,
  listTask,
  sendToLanding,
  setCancel,
  changeReapplyState,
  listWait,
  listNeedAuthorization,
  listCancel,
  listReserveExchange,
  listReservedExchanged,
  listProcessed,
  listReservedAll,
  listMijnCbrQueue,
  listTopCbrQueue,
  find,
  findSimilar,
  findByNumber,
  put,
  bulkSuggestMIJN,
  patch,
  remove,
  markAsProcessed,
  create,
  getCandidateNumber,
  accept,
  removeFromAccept,
  checkReservationQueueBulk,
  sendExchange,
  changeCourseBulk,
  setMijnExam,
  removeMijnExam,
  assignLabels,
  unAssignLabels,
  sendEmail,
  sendEmailBulk,
  addRequests,
  removeRequests,
  sync,
  addCustomLog,
  changeOnHold,
  parseReservationNumber,
  listExchange,
  listReserve,
  changeColor,
  restore,
  softRestore,
  toggleCoursePhotoConsent
};
