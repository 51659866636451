import api from '@/services/api/api-candidates';
import apiLabels from '@/services/api/api-labels';
import ReservationItem from './candidates-reservation-model';
import { CANDIDATE_PAGE, SET_MIJN_ARGS } from './candidates-const';
import { dateFormating, DDMMYYYYFormatting } from '@/util';
import { EMAIL_TEMPLATE_ARGS } from './models/CandidatesEmailTemplateModel';
import HandleFailedModel from './models/candidatesHandleFailedModel';
import { CandidatesApiData } from './candidates-api-data';
import CandidatesReservationRequestsApiData from "./candidates-reservation-requests-api-data"

export default class ReservationService {
  async list(query, page) {
    try {
      if (page === CANDIDATE_PAGE.MIJN_CBR_QUEUE) query = this.removeNotUsedQuery(query);
      let request = this._listSwitcher(page);
      let res = await request(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results.map( item => new ReservationItem(item)),
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        },
        candidates_counts: res.candidates_counts
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  removeNotUsedQuery(query) {
    let _query = {...query};
    if('course_exam_id' in _query) delete _query.course_exam_id;
    if('page_name' in _query) delete _query.page_name;
    if('city' in _query) delete _query.city;
    return _query;
  }

  async fullListIds(query, page) {
    try {
      let request = this._listSwitcher(page)
      let res = await request(query)
      if(!res) throw new Error('Something went wrong and full candidates list is not loading. Please, reload page or try later.')
      const data = res.map(itemId => {
        return {
          ID: itemId
        }
      })
      return data;
    } catch (error) {
      return Promise.reject(error)
    }
  }

  _listSwitcher(page) {
    let apiRequest = api.listTask;
    switch (page) {
    case CANDIDATE_PAGE.TASKS:
      apiRequest = api.listTask;
      break;
    case CANDIDATE_PAGE.WAIT:
      apiRequest = api.listWait;
      break;
    case CANDIDATE_PAGE.NEED_AUTHORIZATION:
      apiRequest = api.listNeedAuthorization;
      break;
    case CANDIDATE_PAGE.TOB_CBR_EXCHANGE_QUEUE:
      apiRequest = api.listExchange;
      break;
    case CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE:
      apiRequest = api.listReserve;
      break;
    case CANDIDATE_PAGE.RESERVE_EXCHANGE:
      apiRequest = api.listReserveExchange;
      break;
    case CANDIDATE_PAGE.RESERVED_EXCHANGED:
      apiRequest = api.listReservedExchanged;
      break;
    case CANDIDATE_PAGE.PROCESSED:
      apiRequest = api.listProcessed;
      break;
    case CANDIDATE_PAGE.CANCELED_LIST:
      apiRequest = api.listCancel;
      break;
    case CANDIDATE_PAGE.RESERVED_ALL:
      apiRequest = api.listReservedAll;
      break;
    case CANDIDATE_PAGE.MIJN_CBR_QUEUE:
      apiRequest = api.listMijnCbrQueue;
      break;
    case CANDIDATE_PAGE.TOP_CBR_QUEUE:
      apiRequest = api.listTopCbrQueue;
      break;
    case CANDIDATE_PAGE.ALL:
      apiRequest = api.list;
      break;
    default:
      break;
    }
    return apiRequest;
  }

  async create(model) {
    try {
      let res = await api.create(model.getApiData())
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async find(ID) {
    try {
      let res = await api.find(ID)
      return res
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  }

  async findSimilar(ID) {
    try {
      let res = await api.findSimilar(ID)
      return res.map(item => new ReservationItem(item))
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  }

  async findByNumber(number) {
    try {
      let res = await api.findByNumber(number)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async put(ID, model) {
    try {
      let res = await api.put(ID, model.getApiData())
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async patchCandidate(ID, changedFields) {
    try {
      let apiData = new CandidatesApiData(changedFields).apiData()
      let isExchangeReservation = changedFields.exam && changedFields.exam.location
      if(changedFields.requests) apiData.requests = new CandidatesReservationRequestsApiData(changedFields.requests).apiData(isExchangeReservation)
      let res = await api.patch(ID, apiData, {params: {with_response: true}})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async remove(ID) {
    try {
      let res = await api.remove(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setCancel(ID) {
    try {
      let res = await api.setCancel(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeReapplyState(ID, datetime) {
    try {
      return await api.changeReapplyState(ID, {force_reapply_allowed_until: datetime});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async restore(ID) {
    try {
      let res = await api.restore(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async softRestore(ID) {
    try {
      let res = await api.softRestore(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async markAsProcessed(ids) {
    try {
      let res = await api.markAsProcessed({ids: ids})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCandidateNumber(ID) {
    try {
      let res = await api.getCandidateNumber({id: ID})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async accept(ids, props) {
    try {
      let res = await api.accept({
        ids: ids
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async checkReservationQueueBulk(ids) {
    try {
      let res = await api.checkReservationQueueBulk({ids})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateReservations(ID, data) {
    try {
      let res = await api.patch(ID, {exam_reservation_requests: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateMijnExam(ID, data) {
    try {
      let res = await api.patch(ID, {is_mijn_reservation: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async saveNote(ID, data) {
    try {
      let res = await api.patch(ID, {notes: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async saveRestoreAfterExam(ID, data) {
    try {
      let res = await api.patch(ID, {restore_after_exam: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async saveColor(ids, colorID) {
    try {
      let res = await api.changeColor(ids, colorID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addCustomLog(ID, data) {
    try {
      let res = await api.addCustomLog(ID, {comment: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async saveIsWaitListStatus(ID, data) {
    try {
      let res = await api.patch(ID, {is_wait_list: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendExchange({ids, exchangeRequests, content, subject, isExchange = false}) {
    try {
      let res = await api.sendExchange({ids: ids, exams: exchangeRequests, content: content, subject: subject, is_exchange: isExchange})
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addRequests({ids, exchangeRequests}) {
    try {
      let res = await api.addRequests({ids: ids, exam_requests: exchangeRequests})
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeRequests({ids, exchangeRequests}) {
    try {
      let res = await api.removeRequests({ids: ids, exams: exchangeRequests})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getLabels() {
    try {
      let res = await apiLabels.list()
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async setMijnExam(ID, data) {
    try {
      let res = await api.setMijnExam(ID, {
        [SET_MIJN_ARGS.LOCATION]: data.location,
        [SET_MIJN_ARGS.PRODUCT_NAME]: data.productName,
        [SET_MIJN_ARGS.DATETIME]: `${DDMMYYYYFormatting(data.examDate)} ${data.examTime}`,
        [SET_MIJN_ARGS.COURSE]: data.course.ID,
        reservation_code: data.reservationCode
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeMijnExam(ID) {
    try {
      let res = await api.removeMijnExam(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async parseReservationNumber(ID) {
    try  {
      return await api.parseReservationNumber(ID);
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeCourseBulk(IDs, data) {
    try  {
      return await api.changeCourseBulk({
        ids: IDs,
        course_id: data.course.ID
      });
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async suggestMijn(IDs, data) {
    try {
      let res = await api.bulkSuggestMIJN( {
        ids: IDs,
        exam: {
          course_id: data.course.ID,
          location: data.location,
          exam_date: dateFormating(data.examDate).join('-'),
          exam_time_from: data.timeFrom,
          exam_time_to: data.timeTo,
        },
        subject: data.subject,
        content: data.content

      })
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async assignLabels(candidates, labels) {
    try {
      let res = await api.assignLabels({
        candidates: candidates,
        labels: labels
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async unAssignLabels(candidates, labels) {
    try {
      let res = await api.unAssignLabels({
        candidates: candidates,
        labels: labels
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateLabels(ID, data) {
    try {
      let res = await api.patch(ID, {labels: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateWillCome(ID, data) {
    try {
      let res = await api.patch(ID, {will_come_to_course: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async patch(ID, data) {
    try {
      return await api.patch(ID, data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async removeFromAccept(ids) {
    try {
      let res = await api.removeFromAccept({ ids })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sync(ids) {
    try {
      let res = await api.sync({ ids })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendToLanding(ID) {
    try {
      let res = await api.sendToLanding(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendEmail(ID, payload) {
    try {
      let data = {
        [EMAIL_TEMPLATE_ARGS.LABEL]: payload.label,
        [EMAIL_TEMPLATE_ARGS.SUBJECT]: payload.subject || '',
        [EMAIL_TEMPLATE_ARGS.CONTENT]: payload.content || ''
      }
      let res = await api.sendEmail(ID, data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async toggleCoursePhotoConsent(ID) {
    try {
      return await api.toggleCoursePhotoConsent(ID);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async sendEmailBulk(IDs, payload) {
    try {
      let data = {
        ids: IDs,
        [EMAIL_TEMPLATE_ARGS.LABEL]: payload.label,
        [EMAIL_TEMPLATE_ARGS.SUBJECT]: payload.subject || '',
        [EMAIL_TEMPLATE_ARGS.CONTENT]: payload.content || ''
      }
      let res = await api.sendEmailBulk(data)
      return {
        error: res.error,
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeOnHold(ids, status) {
    try {
      let res = await api.changeOnHold( {
        ids: ids,
        is_on_hold: status
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateExams(ID, data) {
    try {
      let res = await api.patch(ID, {exams: data})
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
