import { dateFormating } from "@/util"

export default class CandidateReservationRequestsApiData {
  constructor(requests) {
    this.requests = requests
  }
  _getRequestsApiData() {
    return this._getRequestsApiDataBy(this.filteredRequests)
  }

  apiData(isExchange) {
    // Todo need to refactor
    let exchange = isExchange
    let requests
    if(!this.requests || !this.requests.length || !this.requests[0].course.ID) return []
    if(exchange && !isExchange) requests = this.requests.filter(request => request.isExchange)
    else requests = this.requests.filter(request => !request.isExchange)
    requests = requests.map(request => {
      return {
        location: request.location,
        exam_date: this._getDateApiData(request.examDate),
        exam_time_from: request.timeFrom,
        exam_time_to: request.timeTo,
        course_id: request.course.ID,
        is_on_hold: request.onHold,
        comment: request.comment || '',
        exam_comment: request.examComment || '',
        is_exchange: exchange,
      }
    })
    return requests
  }

  _getDateApiData(date) {
    let _date = dateFormating(date)
    if(!_date) return null
    return _date.join('-')
  }
}